<template>
  <v-container>
    <div class="content d-flex justify-center align-center">
      <div class="d-flex flex-column align-center">
        <div>
          <span>4</span>
          <span>0</span>
          <span>4</span>
          <div class="page-not-found">{{ $t("notFound.pageNotFound") }}</div>
        </div>

        <router-link class="home-page" to="/"
          >{{ $t("notFound.homePage") }}
        </router-link>
      </div>
    </div>
  </v-container>
</template>
<script>
export default {
  components: {},

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.content {
  min-height: calc(100vh - 255px);
}

div > span {
  font-size: 170px;
  font-weight: 600;
  line-height: 100px;
}

a.home-page {
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  color: black;
}
a.home-page:hover {
  color: #d4af37;
}

.page-not-found {
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
}

@media (max-width: 425px) {
  h1 {
    font-size: 30px !important;
    text-align: center;
  }

  div > span {
    font-size: 100px;
    font-weight: 600;
    line-height: 100px;
  }
}
</style>
